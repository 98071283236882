import React, { useReducer } from 'react'
import KeepStockContext from '../KeepStockContext'

const UPDATE_KEEP_STOCK = 'UPDATE_KEEP_STOCK'
const RESET_KEEP_STOCK = 'RESET_KEEP_STOCKS'

const keepStockReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_KEEP_STOCK:
      const newState = { ...state, ...action.payload }
      for (const key in newState) {
        if (newState[key]?.incrementToShip === 0) {
          delete newState[key]
        }
      }
      return newState
    case RESET_KEEP_STOCK:
      return {}
    default:
      return state
  }
}

const KeepStockProvider = ({ children }) => {
  const [items, dispatch] = useReducer(keepStockReducer, {})

  const onChange = (item, qty) => {
    if (!item || isNaN(qty) || qty < 0) return

    const { key, maxQty, site } = item

    dispatch({
      type: UPDATE_KEEP_STOCK,
      payload: {
        [key]: {
          item: { ...item, product: { site } },
          incrementToShip: Math.max(0, Math.min(Number(qty), maxQty)),
        },
      },
    })
  }

  const reset = () =>
    dispatch({
      type: RESET_KEEP_STOCK,
    })

  return (
    <KeepStockContext.Provider
      value={{
        items,
        onChange,
        reset,
        count: Object.values(items).reduce((acc, curr) => (acc += curr?.incrementToShip), 0),
      }}
    >
      {children}
    </KeepStockContext.Provider>
  )
}

export default KeepStockProvider
