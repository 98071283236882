import React, { useEffect, useState } from 'react'
import { CS_ROLE, HTA_DEALER_ROLE, SALES_ROLE } from '../../../../../server/constants/roles'
import { useMe } from '../../../api/users'
import AuthContext from '../AuthContext'
import * as Sentry from '@sentry/react'
import { formatDuration, intervalToDuration, parseISO } from 'date-fns'
import { PRODUCT_TYRE, prioritySequence } from '../../../../../shared/constants/product-types'
import { htgCompanyOptions } from '../../../../../shared/config/regional-configs'
import { uniq } from 'lodash'
import { ALLOWEDBRANDS as BMW_ALLOWEDBRANDS } from '../../../../admin/components/localPanel/bmw/AllowedBrands'
import { ALLOWEDBRANDS as MB_ALLOWEDBRANDS } from '../../../../admin/components/localPanel/mercedes/AllowedBrands'
import { ALLOWEDBRANDS as JLR_ALLOWEDBRANDS } from '../../../../admin/components/localPanel/jlr/AllowedBrands'
import { ALLOWEDBRANDS as VW_ALLOWEDBRANDS } from '../../../../admin/components/localPanel/vw/AllowedBrands'
import { ALLOWEDBRANDS as BYD_ALLOWEDBRANDS } from '../../../../admin/components/localPanel/byd/AllowedBrands'
import { BMW, MERCEDES, JLR, VW, BYD } from '../../../../../shared/constants/hta-types'

const getTrialDuration = (expiresAt) => {
  const now = new Date()
  if (!expiresAt || expiresAt < now) {
    return 'Expired'
  }
  const newDuration = intervalToDuration({ start: now, end: expiresAt })
  const lessThanOneDay = expiresAt - now < 86400000

  const format = [
    newDuration.months !== 0 && 'months',
    newDuration.days !== 0 && 'days',
    lessThanOneDay && newDuration.days === 0 && newDuration.hours !== 0 && 'hours',
    lessThanOneDay && newDuration.days === 0 && newDuration.minutes !== 0 && 'minutes',
    lessThanOneDay && newDuration.days === 0 && newDuration.hours === 0 && 'seconds',
  ].filter(Boolean)
  return (
    formatDuration(newDuration, {
      format,
      zero: true,
    }) + ' left'
  )
}

export const getProductTypes = (user) => {
  if ([SALES_ROLE, CS_ROLE].includes(user?.role)) {
    return Array.isArray(user?.productTypes) ? user?.productTypes : []
  }
  if ([HTA_DEALER_ROLE].includes(user?.role)) {
    return [PRODUCT_TYRE]
  }
  return uniq(
    (user?.companies ?? [])
      .map(
        ({ htgCompany }) =>
          htgCompanyOptions[user?.country]?.find(({ value }) => htgCompany === value)
            ?.productTypes ?? []
      )
      .flat()
  )
}

const AuthProvider = ({ children }) => {
  const { data: user, isLoading, refetch } = useMe()
  const [trialDuration, setTrialDuration] = useState()

  if (user?.role === HTA_DEALER_ROLE) {
    switch (user.htaConfig.type) {
      case BMW:
        user.visibleBrands = BMW_ALLOWEDBRANDS
        break
      case MERCEDES:
        user.visibleBrands = MB_ALLOWEDBRANDS
        break
      case JLR:
        user.visibleBrands = JLR_ALLOWEDBRANDS
        break
      case VW:
        user.visibleBrands = VW_ALLOWEDBRANDS
        break
      case BYD:
        user.visibleBrands = BYD_ALLOWEDBRANDS
        break
    }
  }

  const productTypes = getProductTypes(user)

  const keepStockTypes = (user?.productConfig || [])
    .filter(({ accessKeepStock }) => accessKeepStock)
    .map(({ productType }) => productType)

  const reorderedProductTypes = productTypes
    .filter((item) => prioritySequence.includes(item))
    .sort((a, b) => prioritySequence.indexOf(a) - prioritySequence.indexOf(b))

  const updateUser = () => {
    refetch()
  }

  const logout = () => {
    Sentry.configureScope((scope) => scope.setUser(null))
    window.location.href = '/logout'
  }

  useEffect(() => {
    Sentry.setUser({ email: user?.email })
    Sentry.setTag('client', 'frontend')
  }, [user?.email])

  useEffect(() => {
    let interval
    if (user?.trialConfig?.isTrial) {
      interval = setInterval(() => {
        try {
          if (!user?.trialConfig?.expiresAt) {
            return
          }
          const expiresAt = parseISO(user?.trialConfig?.expiresAt)
          const duration = getTrialDuration(expiresAt)
          setTrialDuration(duration)
        } catch (e) {
          console.error(e)
        }
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [user?.trialConfig?.expiresAt, user?.trialConfig?.isTrial])

  let trialConfig = {
    ...(user?.trialConfig ?? {}),
    duration: user?.trialConfig?.isTrial ? trialDuration : '',
    trialExpired:
      user?.trialConfig?.isTrial &&
      (!user?.trialConfig?.expiresAt || parseISO(user?.trialConfig?.expiresAt) < new Date()),
    warning:
      user?.trialConfig?.isTrial &&
      (!user?.trialConfig?.expiresAt ||
        parseISO(user?.trialConfig?.expiresAt) - new Date() <= 1000 * 60 * 60 * 24 * 7),
  }
  let isAuthorized =
    Boolean(user?.id) && (!trialConfig?.isTrial || !trialConfig?.trialExpired) && user?.isActive
  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthorized,
        logout,
        updateUser,
        country: user?.country,
        hasCartAccess:
          !!isAuthorized &&
          user?.accessConfig?.cart &&
          ![CS_ROLE].includes(user?.role) &&
          !(user?.role === HTA_DEALER_ROLE && !user.htaConfig?.allowOrder),
        canViewPrice: user?.showPrice,
        canViewRRP: user?.showRRP,
        canViewWholesalePrice: user?.showWholesalePrice,
        trialConfig,
        productTypes: reorderedProductTypes,
        visibleOrderSites: user?.visibleOrderSites ?? [],
        keepStockTypes,
      }}
    >
      {!user && isLoading ? null : children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
